"use strict"
export const activeQuizz = () => {
    var root = document.getElementById("quizz"),
        quizz = new Quizz(root, { steps: quizzData });
    quizz.init();
    var btn = document.getElementById("active-quizz"),
        main = document.querySelector("main"),
        footer = document.querySelector("footer"),
        header = document.querySelector("header");
    quizz = document.getElementById("quizz");
    quizz.style.opacity = 0,
        quizz.style.display = "none",
        btn.addEventListener("click", (function () { main.style.opacity = "0", footer.style.opacity = "0", header.style.opacity = "0", setTimeout((function () { main.style.display = "none", footer.style.display = "none", header.style.display = "none", quizz.style.display = "block", quizz.style.opacity = 1 }), 200) }));
    var form = document.querySelector(".q-form"); form.addEventListener("submit", (function (e) {
        e.preventDefault();
        var t = Array.prototype.filter.call(form.querySelectorAll("input"), (function (e) { return "text" === e.type || "number" === e.type ? e.name && e.value : e.name && e.checked })).map((function (e) { return "".concat(e.name, ": ").concat(e.value) })).join("\n"), n = new FormData; n.append("message", t); var o = new XMLHttpRequest; o.open("POST", "./send.php"), o.addEventListener("load", (function (e) { if (200 === e.target.status) return window.location.href = "./thank.html"; alert("Ошибка при отправке данных. Попробуйте снова.") }), !1), o.send(n)
    }));
}